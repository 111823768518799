import { graphql } from "gatsby"
import React, { useState } from "react"
import Layout from "../components/Layout"
import SearchBar from "../components/SearchBar"
import _ from "lodash"

import { useFlexSearch } from "react-use-flexsearch"

const SearchPage = ({
  location, // from gatsby page prop
  data: {
    localSearchPages: { index, store },
    allMarkdownRemark: { nodes },
  },
}) => {
  const { search } = location
  const query = new URLSearchParams(search).get("s")
  const [searchQuery, setSearchQuery] = useState(query || "")

  const results = useFlexSearch(searchQuery, index, store)
  const posts = searchQuery ? unflattenResults(results) : nodes

  return (
    <Layout location={location} seoTitle={"Search"}>
      <SearchBar
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        location={location}
      />
      <h1>Search Result</h1>
      {posts.map(post => (
        <div>
          {_.get(post, "frontmatter.title")
            ? post.frontmatter.title
            : "undefined"}
        </div>
      ))}
    </Layout>
  )
}

export default SearchPage

export const pageQuery = graphql`
  query {
    localSearchPages {
      index
      store
    }
    allMarkdownRemark(sort: { fields: [frontmatter___title], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          title
        }
      }
    }
  }
`
const unflattenResults = results =>
  results.map(post => {
    const { slug, title } = post
    return { slug, frontmatter: { title } }
  })
